<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-responsive min-height="350">
          <v-container>
            <transition-group
              tag="v-row"
              name="fade-transition"
            >
              <v-col
                v-for="project in computedProjects"
                :key="project.img"
                cols="12"
                class="gallery-card"
                md="4"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      :img="`/static/${project.img}`"
                      class="white--text overflow-hidden"
                      dark
                      height="300"
                      hover
                      @click="overlay = project.img"
                    >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                          <v-icon large>
                            mdi-magnify
                          </v-icon>
                        </v-overlay>
                      </v-fade-transition>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </transition-group>
          </v-container>
        </v-responsive>

        <v-fade-transition mode="out-in">
          <v-overlay
            v-if="overlay"
            fixed
            opacity=".9"
          >
            <v-btn
              color="transparent"
              depressed
              fab
              fixed
              large
              right
              top
              @click="overlay = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>

            <v-img
              :src="`/static/${overlay}`"
              width="800"
              max-width="90vw"
            />
          </v-overlay>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="10"
        offset-md="1"
      >
        <base-subheading>
          <h1
            class="mt-5 mb-5 text-center"
            style="font-family: 'Skia', sans-serif;"
          >
            EDMUNDOWO PARK
          </h1>
        </base-subheading>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>LOKALIZACJA</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          <strong>Edmundowo-Jankowice</strong>, gmina <strong>Tarnowo Podgórne</strong>. W pobliżu tereny zielone: las, <b>zespół pałacowo-parkowy Jankowice</b> (<a href="https://www.palacjankowice.pl/">www.palacjankowice.pl</a>). W odległości około 4km dostęp do pełnej infrastruktury Tarnowa Podgórnego (szkoły, przedszkola, sklepy, restauracje, baseny - Tarnowskie Termy, itp.). Kameralną lokalizację wyróżniają piękne krajobrazy, cisza i spokój oraz pełna infrastruktura drogowa.
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>NIERUCHOMOŚCI</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          Na terenie 6000 m² docelowo powstanie 6 budynków jednorodzinnych dwulokalowych (zabudowa bliźniacza). Inwestycja podzielona jest na 2 etapy. W pierwszym etapie (maj 2024) do oddania w standardzie deweloperskim będą 3 <strong>budynki parterowe</strong> - łącznie <strong>6 lokali mieszkalnych</strong> o powierzchni <b>103 m²</b> każdy.
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>STAN DEWELOPERSKI ROZSZERZONY</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          Możliwość poszerzenia stanu deweloperskiego o <strong>instalację fotowoltaiczną</strong> oraz instalację systemu smart home.
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>WYKOŃCZENIA WNĘTRZ</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          Dla zainteresowanych klientów oferujemy możliwość wykończenia wnętrz w 3 standardach, według projektów naszych architektów.  Wymagającym klientom proponujemy indywidualny projekt wnętrza wraz z wyposażeniem.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="10"
        offset-md="1"
      >
        <base-subheading>
          <h1
            class="mt-5 mb-5 text-center"
            style="font-family: 'Skia', sans-serif;"
          >
            STANDARDY WYKOŃCZENIA
          </h1>
        </base-subheading>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>STAN DEWELOPERSKI O PODWYŻSZONYM STANDARDZIE</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          <ul>
            <li>ściany zewnętrzne oraz działowe z cegły ceramicznej Porotherm</li>
            <li>dach - prefabrykowana konstrukcja z wiązarów, dachówka antracytowa</li>
            <li>konstrukcja dachowa przygotowana pod fotowoltaikę</li>
            <li>budynek ocieplony styropianem 20 cm</li>
            <li>sufity z nida-gips przeszpachlowane z siatką dla wzmocnienia, docieplone wełną</li>
            <li>tynki wewnętrzne gipsowe maszynowe, posadzki maszynowe</li>
            <li>ogrzewanie podłogowe</li>
            <li>drzwi wejściowe w kolorze antracyt</li>
            <li>okna: 3 szybowe antracyt z roletami</li>
            <li>2 okna przesuwne balkonowe w systemie HS</li>
            <li>podjazdy i chodniki z kostki poz-bruk</li>
            <li>miejsce parkingowe - z możliwością rozszerzenia o pergolę</li>
            <li>działka ogrodzona panelem ogrodzeniowym (antracyt)</li>
            <li>media: prąd, woda, gaz, kanaliza, światłowód</li>
            <li>ogrzewanie: piec gazowy 2 funkcyjny (możliwość rozszerzenia o zbiornik)</li>
            <li>rozprowadzona instalacja elektryczna wraz z włącznikami i gniazdami</li>
            <li>rozprowadzona instalacja pod montaż alarmu</li>
            <li>rozprowadzona instalacja pod montaż kamer</li>
            <li>rozprowadzona instalacja antenowa i internetowa</li>
          </ul>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>OFERUJEMY ROZSZERZENIE STANDARDU DEWELOPERSKIEGO O:</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          <ul>
            <li>wykończenie wnętrza - 3 standardy lub projekt indywidualny</li>
            <li>strych z możliwością lekkiego składowania</li>
            <li>instalację fotowoltaiczną</li>
            <li>zaprojektowanie i założenie ogrodu</li>
          </ul>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>USŁUGI DODATKOWE</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          <ul>
            <li>bezpłatna pomoc w uzyskaniu kredytu hipotecznego na najlepszych warunkach rynkowych</li>
          </ul>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>LOKALE MIESZKALNE 103 m²</b>
        </p>
        <p
          class="text-justify"
          style="font-family: 'Skia', sans-serif;"
        >
          <ul>
            <li>
              Ceny mieszkań zaczynają od 759 000 zł <br>Ostateczna kwota zależy od wielkości działki i wybranych opcji wykończenia.
            </li>
          </ul>
        </p>
        <p>
          <img
            src=""
          >
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          <b>PLIKI DO POBRANIA</b>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          Prezentacja inwestycji
          <a
            href="/static/karty_lokali/edmundowo-park.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          Rzut inwestycji - lokale z działkami
          <a
            href="/static/karty_lokali/edmundowo-park_inwestycja.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_inwestycja.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          Rzut inwestycji - udziały w gruncie
          <a
            href="/static/karty_lokali/edmundowo-udzial-grunt.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_inwestycja.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          Rzut lokalu - lewa strona
          <a
            href="/static/karty_lokali/ze-rzut-2.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/ze-rzut-2.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          Rzut lokalu - prawa strona
          <a
            href="/static/karty_lokali/ze-rzut-1.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/ze-rzut-1.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:red;"
        >
          Karta lokalu 1A - SPRZEDANY
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_1A.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_1A.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:red;"
        >
          Karta lokalu 1B - SPRZEDANY
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_1B.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_1B.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green;"
        >
          Karta lokalu 2A - cena: ZAPYTAJ O CENĘ
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_2A.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_2A.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green;"
        >
          Karta lokalu 2B - cena: ZAPYTAJ O CENĘ
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_2B.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_2B.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green;"
        >
          Karta lokalu 3A - cena: <strong>759 000 zł</strong> - data ukończenia 1 czerwca 2024
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_3A.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_3A.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green"
        >
          Karta lokalu 3B - cena: <strong>759 000 zł</strong> - data ukończenia 1 czerwca 2024
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_3B.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_3B.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green;"
        >
          Karta lokalu 4A - cena: ZAPYTAJ O CENĘ
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_4A.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_4A.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green;"
        >
          Karta lokalu 4B - cena: ZAPYTAJ O CENĘ
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_4B.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_4B.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green;"
        >
          Karta lokalu 5A - cena: 825 000 zł
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_5A.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_5A.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:orange;"
        >
          Karta lokalu 5B - cena: 875 000 zł
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_5B.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_5B.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:orange"
        >
          Karta lokalu 6A - cena: 875 000 zł
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_6A.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_6A.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          style="font-family: 'Skia', sans-serif;color:green;"
        >
          Karta lokalu 6B - cena: 825 000 zł
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_6B.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/karty_lokali/edmundowo-park_lokal_6B.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
        <p
          class="text-right"
          style="font-family: 'Skia', sans-serif;font-size: 10px;"
        >
          <i>Powyższe ogłoszenie ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu art.66 §1 Kodeksu Cywilnego</i>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex'

  export default {
    data: () => ({
      category: null,
      categories: [
        {
          'text': 'WSZYSTKIE PROJEKTY',
          'filter': null
        },
        {
          'text': 'W REALIZACJI',
          'filter': 2
        },
        {
          'text': 'W PRZYGOTOWANIU',
          'filter': 3
        },
        {
          'text': 'INSIDE',
          'filter': 1
        }
      ],
      overlay: false,
      projects: [
        {
          'name': 'osiedle Zielone Edmundowo',
          'img': 'ze-1.jpg'
        },
        {
          'name': 'parterowe domy o powierzchni 103,1m²',
          'img': 'ze-2.jpg'
        },
        {
          'name': 'w sąsiedztwie pałacu w Jankowicach',
          'img': 'ze-3.jpg'
        },
        {
          'name': 'domy jednorodzinne dwulokalowe',
          'img': 'ze-4.jpg'
        },
        {
          'name': 'przestronne, parterowe lokale mieszkalne',
          'img': 'ze-9.jpg'
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-10.jpg'
        },
        {
          'name': 'Zielone Edmundowo',
          'img': 'ze-rzut-2.jpg'
        },
        {
          'name': 'Edmundowo przy pałacu w Jankowicach',
          'img': 'ze-7.jpg'
        },
        {
          'name': 'przestronne, parterowe lokale mieszkalne',
          'img': 'ze-5.jpg'
        },
        {
          'name': 'w sąsiedztwie parku i pałacu',
          'img': 'ze-rzut-1.jpg'
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-8.jpg'
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-6.jpg'
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-wew-1.jpg'
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-wew-2.jpg'
        },
        {
          'name': 'Edmundowo - lepsza strona Jankowic',
          'img': 'ze-wew-3.jpg'
        }
      ]
    }),

    computed: {
      computedProjects () {
        return !this.category
          ? this.projects
          : this.projects.filter(p => p.categories.includes(this.category))
      }
    },

    methods: {
      ...mapMutations(['toggleDrawer']),
      select (category) {
        this.category = category.filter
      }
    }
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
